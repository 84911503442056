<template>
  <v-form v-model="valid">
    <v-text-field
      label="Enter March Size"
      v-model="finalMarchSize"
      filled
      placeholder=" "
    ></v-text-field>
    <v-row>
      <v-col cols="4">
        <v-text-field
          :full-width="false"
          v-model="inputInfantryPercentage"
          :label="marchTypeInfantryLabel"
          :placeholder="this.marchTypeInfantryLabelPer"
          :value="this.marchTypeInfantryLabelPer.toLocaleString()"
          suffix="%"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="inputRidersPercentage"
          :label="this.marchTypeRidersLabel"
          :placeholder="this.marchTypeRidersLabelPer"
          :value="this.marchTypeRidersLabelPer.toLocaleString()"
          suffix="%"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="inputHuntersPercentage"
          :label="this.marchTypeHuntersLabel"
          :placeholder="this.marchTypeHuntersLabelPer"
          :value="this.marchTypeHuntersLabelPer.toLocaleString()"
          suffix="%"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-text-field
          :label="marchTypeInfantryLabel"
          :placeholder="this.marchTypeInfantryLabel"
          :value="this.marchTypeInfantryPercentage.toLocaleString()"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          :label="this.marchTypeRidersLabel"
          :placeholder="this.marchTypeRidersLabel"
          :value="this.marchTypeRidersPercentage.toLocaleString()"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          :label="this.marchTypeHuntersLabel"
          :placeholder="this.marchTypeHuntersLabel"
          :value="this.marchTypeHuntersPercentage.toLocaleString()"
          readonly
        ></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  name: 'MarchCalculator',
  props: {
    marchSize: Number,
    configData: Object
  },
  data () {
    return {
      valid: '',
      inputInfantryPercentage: this.configData.percentages[0].value,
      inputRidersPercentage: this.configData.percentages[1].value,
      inputHuntersPercentage: this.configData.percentages[2].value,
      finalMarchSize: this.$store.getters.getMarchSize
    }
  },
  computed: {
    marchTypeInfantryPercentage: function () {
      return parseInt(this.finalMarchSize / 100 * this.inputInfantryPercentage)
    },
    marchTypeRidersPercentage: function () {
      return parseInt(this.finalMarchSize / 100 * this.inputRidersPercentage)
    },
    marchTypeHuntersPercentage: function () {
      return parseInt(this.finalMarchSize / 100 * this.inputHuntersPercentage)
    },
    marchTypeInfantryLabel: function () {
      return `${this.configData.percentages[0].label}`
    },
    marchTypeRidersLabel: function () {
      return `${this.configData.percentages[1].label}`
    },
    marchTypeHuntersLabel: function () {
      return `${this.configData.percentages[2].label}`
    },
    marchTypeInfantryLabelPer: function () {
      return `${this.configData.percentages[0].value}`
    },
    marchTypeRidersLabelPer: function () {
      return `${this.configData.percentages[1].value}`
    },
    marchTypeHuntersLabelPer: function () {
      return `${this.configData.percentages[2].value}`
    }
  },
  watch: {
    finalMarchSize: function (newValue) {
      this.$store.commit('updateMarchSize', parseInt(newValue))
    },
    marchTypeInfantryPercentage: function (newValue) {
      this.$store.commit('updateInfantrySize', newValue)
    },
    marchTypeRidersPercentage: function (newValue) {
      this.$store.commit('updateRiderSize', newValue)
    },
    marchTypeHuntersPercentage: function (newValue) {
      this.$store.commit('updateHunterSize', newValue)
    }
  }
}
</script>

<style scoped>

</style>
