import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

const vuexPersist = new VuexPersistence({
  key: 'soscalc',
  storage: window.localStorage
})

console.log(vuexPersist)

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    marchSize: 0,
    infantrySize: 0,
    hunterSize: 0,
    riderSize: 0,
    isDark: false,
    version: ''
  },
  mutations: {
    updateMarchSize (state, val) {
      state.marchSize = val
    },
    updateInfantrySize (state, val) {
      state.infantrySize = val
    },
    updateHunterSize (state, val) {
      state.hunterSize = val
    },
    updateRiderSize (state, val) {
      state.riderSize = val
    },
    updateDarkMode (state, val) {
      state.isDark = val
    },
    updateVersion (state, val) {
      state.version = val
    },
    RESTORE_MUTATION: vuexPersist.RESTORE_MUTATION
  },
  getters: {
    getMarchSize: state => {
      return state.marchSize
    },
    getInfSize: state => {
      return state.infantrySize
    },
    getHuntSize: state => {
      return state.hunterSize
    },
    getRiderSize: state => {
      return state.riderSize
    },
    isDarkMode: state => {
      return state.isDark
    },
    getVersion: state => {
      return state.version
    }
  },
  actions: {

  },
  plugins: [vuexPersist.plugin]
})

export default store
