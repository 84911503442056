<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-tabs
            v-model="tab"
            center-active
            :background-color="(isDarkMode) ? 'black' : 'white'"
            :dark="isDarkMode">
            <v-tab
              v-for="item in tabItems"
              :key="item.key">
              {{ item.tab }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-card flat :dark="isDarkMode">
                <v-col cols="12">
                  <MarchCalculator v-if="this.isLoaded"
                                   :config-data="this.configData.config.games[0]"></MarchCalculator>
                </v-col>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat :dark="isDarkMode" class="pt-5">
                <v-col cols="12">
                  <MarchCalculator v-if="this.isLoaded"
                                   :config-data="this.configData.config.games[1]"></MarchCalculator>
                </v-col>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat :dark="isDarkMode" class="pt-5">
                <v-col cols="12">
                  <MarchCalculator v-if="this.isLoaded"
                                   :config-data="this.configData.config.games[2]"></MarchCalculator>
                </v-col>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat :dark="isDarkMode" class="pt-5">
                <v-col cols="12">
                  <MarchCalculator v-if="this.isLoaded"
                                   :config-data="this.configData.config.games[3]"></MarchCalculator>
                </v-col>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      width="500"
      :dark="isDarkMode"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          dark
          v-on="on"
        >
          <v-icon class="mr-2">mdi-information</v-icon>
          Feedback
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2">
          Feedback Form
        </v-card-title>

        <v-row>
          <v-col cols="10" class="mx-auto">
            <v-form
              name="feedback"
              method="post"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              @submit.prevent="handleSubmit"
            >
              <input type="hidden" name="form-name" value="feedback"/>
              <v-text-field
                name="playername"
                v-model="form.playername"
                label="Player Name"
              ></v-text-field>
              <v-textarea
                name="message"
                label="Message"
                v-model="form.message"
              ></v-textarea>
              <v-btn class="mr-4" type="submit">submit</v-btn>
            </v-form>
          </v-col>
        </v-row>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            @click="dialog = false"
          >
            Cancel2
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios'
import MarchCalculator from '../components/MarchCalculator'

export default {
  name: 'Home',
  components: { MarchCalculator },
  data () {
    return {
      dialog: false,
      form: {
        playername: '',
        message: ''
      },
      valid: '',
      baseUrl: process.env.DEPLOY_PRIME_URL,
      configData: {},
      troops: [],
      tab: null,
      tabItems: [],
      marchSize: '',
      trooptype: '',
      isLoaded: false,
      isDarkMode: this.$store.getters.isDark
    }
  },
  computed: {},
  methods: {
    encode (data) {
      return Object.keys(data)
        .map(
          key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join('&')
    },
    handleSubmit () {
      const axiosConfig = {
        header: { 'Content-Type': 'application/x-www-form-urlencoded' }
      }
      axios.post(
        '/',
        this.encode({
          'form-name': 'feedback',
          ...this.form
        }),
        axiosConfig
      ).then(() => {
        alert('thanks')
      }).catch(() => {
        alert('oops')
      })
    },
    fetchData () {
      axios.get('/data.json').then(response => {
        if (response.status === 200) {
          this.configData = response.data
          this.troops = response.data.config.troops[0]
          this.createTabsByGame()
          this.$store.commit('updateVersion', this.configData.config.version)
          this.isLoaded = true
        } else {
          alert('config could not be loaded.')
        }
      })
    },
    createTabsByGame () {
      const tabData = this.configData.config.games
      let arr = []
      tabData.forEach((tab) => {
        arr.push({ tab: tab.title, key: tab.id })
      })
      this.tabItems = arr
    }
  },
  created () {
    this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case 'updateDarkMode':
          this.isDarkMode = state.isDark
          break
        case 'RESTOREMUTATION':
          this.isDarkMode = state.isDark
          break
      }
    })

    this.fetchData()
  }
}
</script>
