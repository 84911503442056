<template>
  <div id="app" data-server-rendered="true" :class="{ dark: isDark }">
    <v-app :class="{ dark: isDark }">
      <v-app-bar
        app
        :color="(isDark) ? 'black' : 'white'"
        :dark="isDark"
        :class="{ dark: isDark }"
      >
        <div class="d-flex align-center">
          <v-img
            alt=""
            class="shrink mr-2"
            contain
            src="../public/img/logo.png"
            transition="scale-transition"
            width="80"
          />
          <h3>Troop Calculator<small> {{this.$store.getters.getVersion}}</small></h3>
        </div>
        <v-spacer></v-spacer>
        <v-switch
          v-model="isDark"
          class="pt-5"
        ></v-switch>
      </v-app-bar>
      <v-main>
        <router-view/>
      </v-main>
    </v-app>
  </div>
</template>

<script>

export default {
  name: 'App',
  data () {
    return {
      isDark: false
    }
  },
  created () {
    this.$store.commit('updateDarkMode', false)
  },
  watch: {
    isDark: function (newValue) {
      this.$store.commit('updateDarkMode', newValue)
    }
  }
}
</script>
<style lang="scss">
  #app {
    background: #fff;

    &.dark {
      background: #000;
    }
  }

  .v-label {
    font-size: 1.2rem !important;
    top: -4px;
    font-weight: bold;
  }

  .v-text-field input {
    font-size: 1.4rem !important;
  }
</style>
